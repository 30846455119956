.step-container{
    height: 90vh;
    overflow-y: auto;
    overflow-x: hidden;
    position: relative;
  }
  


  @media (min-width: 768px) {
    .step-container{

        height: 600px;
    }
}



.modal-close-button{
    z-index: 9999;
    padding: 15px 0px;
    position: relative;
}
.step{
    position: absolute;
    padding: 1px;
    transition: all 0.25s ease-in-out;
    max-width: 100%;
    min-width: 100%;
    top: 0 !important;
    left: 0;
    min-height: 600px;
    right: 0;
    bottom: 0;

&.prev {
    left: -300px;
    opacity: 0;z-index: -1;
  }
  &.next { 
    left: 300px;
    opacity: 0;
    z-index: -1;
  }
  &.current { 
    left: 0;
    opacity: 1;z-index: 0;
  }
}