.popover {
  box-shadow: 0px 0.5rem 2rem 0px rgba(0, 0, 0, 0.4);
  max-width: 100vw !important;
}
.rdtPicker td.rdtDay:hover{ background: inherit !important;}
.rdtDay {
  min-width: 46px;
  height: 46px !important;
  border-radius: 0% !important;

  &.rtdActive {
    background-color: inherit !important;
    color: inherit !important;
  }
}

.rdtPicker {
  width: auto !important;
}
.rdtDays {
  max-width: 100vw !important;
}
.date-picker-week {
  table tbody tr {
    transition: all .15s ease;
    &:hover {
      background-color: #c0c0c0;

    }
   td{transition: all .15s ease;}
    td:first-child {
      border-radius: 50% 0% 0% 50% !important;
    }

    td:last-child {
      border-radius: 0% 50% 50% 0% !important;
    }
  }
}

.date-picker-day {
  table tbody tr {

    td {
      border-radius: 50% 50% !important;
      transition: all .15s ease;
    }

  }
}
