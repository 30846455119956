.sidebar-nav .nav-item {
  position: relative; /* making the .tooltip span a container for the tooltip text */
  z-index: 99999;
    &:before {
      /* reset defaults */
      left: initial;
      margin: initial;

      /* set new values */
      right: 100%;
      margin-right: 15px;
    }
  &:hover {
    &:before,
    &:after {
      display: block;
      opacity: 1;
      z-index:999999;
    }
    &:after {
      opacity: 1;
    }
  }

  &:before {
    content: attr(data-text); /* here's the magic */
    position: absolute;
    opacity: 0;
    transition: 0.3s opacity;
    /* vertically center */
    top: 50%;
    transform: translateY(-50%);

    /* move to right */
    left: 100%;
    margin-left: 6px; /* and add a small left margin */

    /* basic styles */
    width: 180px;
    padding: 5px;
    border-radius: 5px;
    background: #000;
    color: #fff;
    text-align: left;
    padding-left: 15px;
    display: none; /* hide by default */
  }

  &:after {
    content: "";
    position: absolute;
    opacity: 0;
    transition: 0.3s;
    /* position tooltip correctly */
    left: 100%;
    margin-left: -5px;

    /* vertically center */
    top: 50%;
    transform: translateY(-50%);

    /* the arrow */
    border: 6px solid #000;
    border-color: transparent black transparent transparent;

    display: none;
  }
}
