.carousel-item {
    height: 90vh;
    overflow-y: auto;
    overflow-x: hidden;
  }
  
  @media (min-width: 768px) {
    .carousel-item {
      height: 500px;  
    } 
}
