.loader {
    display: inline-block;
    position: relative;


    &.loader-primary{         
      div {       
          border-color:  var(--primary) transparent transparent transparent;
      }
  }

  &.loader-secondary{         
    div {       
        border-color:  var(--secondary) transparent transparent transparent;
    }
}

&.loader-white{         
  div {       
      border-color:  var(--white) transparent transparent transparent;
  }
}
     
div {
    box-sizing: border-box;
    display: block;
    position: absolute;
  border-width: 8px;
  border-style: solid;
    border-radius: 50%;

  }

  &.loader-lg{
    width: 80px;
    height: 80px;
         
    div {
       
        width: 64px;
        height: 64px;
        margin: 8px;
        border-width: 8px;
        border-style: solid;
        animation: loader 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    }
}

    &.loader-xs{
        width: 16px;
        height: 16px;

             
        div {
      
            width: 16px;
            height: 16px;
            margin: 3px;
            border-width: 2px;
            border-style: solid;
            animation: loader 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
           
        }
    }
  }

 

  .loader div:nth-child(1) {
    animation-delay: -0.45s;
  }
  .loader div:nth-child(2) {
    animation-delay: -0.3s;
  }
  .loader div:nth-child(3) {
    animation-delay: -0.15s;
  }
  @keyframes loader {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  